// ** React Imports
import { useState, createContext } from "react"

// ** Intl Provider Import
import { IntlProvider } from "react-intl"

// ** Core Language Data
import messagesEn from "@assets/data/locales/en.json"
import messagesVn from "@assets/data/locales/vn.json"

// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json"
import userMessagesVn from "@src/assets/data/locales/vn.json"

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  vi: { ...messagesVn, ...userMessagesVn }
}

const defaultLocale = "vi"

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState(() => {
    try {
      const item = window.localStorage.getItem("locale")
      return item ? item : defaultLocale
    } catch (error) {
      // ** If error return initialValue
      console.error(error)
      return defaultLocale
    }
  })
  const [messages, setMessages] = useState(() => {
    try {
      const item = window.localStorage.getItem("locale")
      return item ? menuMessages[item] : menuMessages[defaultLocale]
    } catch (error) {
      // ** If error return initialValue
      console.error(error)
      return menuMessages[defaultLocale]
    }
  })

  // ** Switches Language
  const switchLanguage = (lang) => {
    try {
      setLocale(lang)
      setMessages(menuMessages[lang])
      // ** Save to local storage
      window.localStorage.setItem("locale", lang)
    } catch (error) {
      // ** A more advanced implementation would handle the error case
      console.error(error)
    }
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale={defaultLocale}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
