import { Spinner } from "reactstrap"

import "@styles/base/pages/page-misc.scss"

const UserConnected = () => {
  return (
    <div className="misc-wrapper">
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
        <Spinner color="primary" />
        </div>
      </div>
    </div>
  )
}
export default UserConnected
