import { useReactOidc } from "@axa-fr/react-oidc-context"
import notAuthImg from "@src/assets/images/pages/not-authorized.svg"
import { Modal, ModalBody, Spinner } from "reactstrap"

import "@styles/base/pages/page-misc.scss"
import { useEffect } from "react"
import SpinnerComponent from "../../@core/components/spinner/Fallback-spinner"

const Authenticating = () => {
  const { logout } = useReactOidc()
  logout()
  // useEffect(() => {
  //   debugger
  //   // setTimeout(() => {
  //   //   logout()
  //   // }, 500)
  // }, [])

  // return (
  //   <div>
  //     <SpinnerComponent />
  //   </div>
  // )
}
export default Authenticating
