import { Button } from "reactstrap"
import notAuthImg from "@src/assets/images/pages/not-authorized.svg"

// ** oidc
import { useReactOidc } from "@axa-fr/react-oidc-context"

import "@styles/base/pages/page-misc.scss"
import { useEffect } from "react"

const NotAuthenticated = () => {
  const { logout } = useReactOidc()

  useEffect(() => {
    setTimeout(() => {
      logout()
    }, 500)
  }, [])

  return (
    <div className="misc-wrapper">
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">You are not authenticated! 🔐</h2>
          <p className="mb-2"></p>
          <a href="/">
            <Button color="primary" className="btn-sm-block mb-1">
              Back to login
            </Button>
          </a>
          <img
            className="img-fluid"
            src={notAuthImg}
            alt="Not authorized page"
          />
        </div>
      </div>
    </div>
  )
}
export default NotAuthenticated
